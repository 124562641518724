import { createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b97b469"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-customer" }
const _hoisted_2 = { class: "select-customer-form" }
const _hoisted_3 = { class: "select-customer-actions main-modal-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Loader"], { loading: $setup.loading }, null, 8, ["loading"]),
    _createVNode($setup["NForm"], {
      ref: "formRef",
      model: $setup.form,
      rules: $setup.isDirty ? $setup.formRules : undefined,
      onKeydown: _withKeys($setup.handleSubmit, ["enter"])
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode($setup["NDropdown"], {
            class: "main-dropdown filter-menu select-customer-form-item",
            show: $setup.showFilters,
            "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => (($setup.showFilters) = $event)),
            options: $setup.customerOptions,
            placement: "bottom-start",
            trigger: "click"
          }, {
            default: _withCtx(() => [
              _createVNode($setup["NFormItem"], {
                path: "customerId",
                label: "Customer"
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["NInput"], {
                    ref: "inputInstRef",
                    value: $setup.form.customerName,
                    placeholder: "Choose customer",
                    readonly: "",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.inputInstRef?.blur()))
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["show", "options"]),
          _createVNode($setup["NFormItem"], {
            path: "userId",
            label: " Contact person / user"
          }, {
            default: _withCtx(() => [
              _createVNode($setup["NSelect"], {
                value: $setup.form.userId,
                "onUpdate:value": [
                  _cache[2] || (_cache[2] = ($event: any) => (($setup.form.userId) = $event)),
                  $setup.onUpdateUser
                ],
                options: $setup.userOptions,
                placeholder: "Choose contact person / user",
                disabled: !$setup.form.customerId
              }, null, 8, ["value", "options", "disabled"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode($setup["NButton"], {
            class: "update-button",
            type: "info",
            disabled: $setup.loading || !$setup.form.customerId || !$setup.form.userId,
            onClick: _withModifiers($setup.handleSubmit, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createTextVNode("Next")
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])
      ]),
      _: 1
    }, 8, ["model", "rules", "onKeydown"])
  ]))
}