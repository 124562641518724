import { OpenOrderFilterKey } from "@/enums/OpenOrderFilterKey";
import store from "@/store";

const isCustomerUserType = store.getters["user/isCustomerUserType"];

const openOrderFilter = {
  [OpenOrderFilterKey.open]: {
    label: "Open order lines",
    value: false,
  },
  [OpenOrderFilterKey.delivered]: {
    label: isCustomerUserType ? "Delivered order lines" : "Closed order lines",
    value: true,
  },
};

export default openOrderFilter;
