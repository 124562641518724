import { Ref } from "vue/dist/vue";
import QuoteService from "@/services/QuoteService";
import CustomerOrderInterface from "@/types/CustomerOrderInterface";

export const getOrderLinePartPreviews = (
  orders: Ref<CustomerOrderInterface[]>,
  partIdList: string[],
  previewLoading: Ref<boolean>
) => {
  if (!partIdList.length) {
    return;
  }
  previewLoading.value = true;
  QuoteService.getPartPreviews(partIdList)
    .then((res) => {
      const previewList = res.data.data;
      orders.value.forEach((order) => {
        const preview = previewList.find(
          (item: { partId: string; newPartId: string }) =>
            order.part?.partId === item.partId &&
            order.part?.newPartId === item.newPartId
        );
        if (preview) {
          order.thumbnailUrl = preview.link;
        }
      });
    })
    .finally(() => {
      previewLoading.value = false;
    });
};
