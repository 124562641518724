import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.showViewAsACustomerModal)
      ? (_openBlock(), _createBlock($setup["ViewAsACustomerModal"], {
          key: 0,
          show: $setup.showViewAsACustomerModal,
          "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (($setup.showViewAsACustomerModal) = $event)),
          onCustomerChecked: $setup.customerChecked
        }, null, 8, ["show"]))
      : _createCommentVNode("", true),
    _createVNode($setup["DotsMenu"], {
      options: $setup.options,
      onSelectOption: $setup.onSelectOption
    }, {
      default: _withCtx(() => [
        _createVNode($setup["Eye"])
      ]),
      _: 1
    }, 8, ["options"])
  ], 64))
}