export const getThousandWithCommas = (value: string | number): string => {
  if (!value) {
    return "";
  }
  const text = String(value);
  const sanitizedText = text.replace(/[^\d.]/g, "");
  const [integerPart, decimalPart] = sanitizedText.split(".");
  const dotIndex = text.indexOf(".");
  const formattedIntegerPart = (+integerPart).toLocaleString("en-US", {
    maximumFractionDigits: 0,
  });
  if (dotIndex !== -1 && !decimalPart) {
    return `${formattedIntegerPart}.`;
  }
  return decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;
};

export const getNumberFromThousandWithCommas = (
  value: string | number
): number => {
  if (!value) {
    return 0;
  }
  const text = String(value);
  const sanitizedText = text.replace(/[^\d.]/g, "");
  return isNaN(+sanitizedText) ? 0 : +sanitizedText;
};
