import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b5711e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter--actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["NDropdown"], {
      class: "main-dropdown filter-menu",
      show: $setup.showFilters,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (($setup.showFilters) = $event)),
      options: $setup.filterOptions,
      placement: "bottom-start",
      trigger: "click",
      onSelect: $setup.handleMainFiltersSelect
    }, {
      default: _withCtx(() => [
        _createVNode($setup["NButton"], {
          class: "n-button-filter",
          round: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("Add Filter")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["show", "options"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filterListGrouped, (item) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.array, (arrayItem) => {
          return (_openBlock(), _createBlock($setup["FilterTag"], {
            key: arrayItem.key || arrayItem.value,
            label: item.label,
            value: arrayItem.text || arrayItem.value,
            loading: $props.loading,
            onDeleteItem: ($event: any) => ($setup.removeFilterTag(item.parent, arrayItem.key || arrayItem.value))
          }, null, 8, ["label", "value", "loading", "onDeleteItem"]))
        }), 128))
      ], 64))
    }), 256)),
    ($props.filterByOrderType)
      ? (_openBlock(), _createBlock($setup["FilterTag"], {
          key: 0,
          label: "Order type",
          value: "FA",
          loading: $props.loading,
          onDeleteItem: _cache[1] || (_cache[1] = ($event: any) => ($setup.removeFilterTagByOrderType()))
        }, null, 8, ["loading"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      ($setup.showFilterActionButtons)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["clear-filters", {disabled: $props.loading}]),
            role: "button",
            onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.onClearFilterTags()))
          }, "Clear all", 2))
        : _createCommentVNode("", true),
      ($setup.showFilterActionButtons)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["save-filters", {disabled: $props.loading || $setup.isViewAsACustomer}]),
            role: "button",
            onClick: $setup.openSaveFiltersModal
          }, "Save filters", 2))
        : _createCommentVNode("", true)
    ]),
    _createVNode($setup["SaveFilterModal"], {
      showModal: $setup.showSaveFiltersModal,
      groupedFilter: $setup.filterListGroupedPopup,
      filterByOrderType: $props.filterByOrderType,
      category: $setup.orderCategory,
      showTargetShippingDate: $setup.showTargetShippingDate,
      onFilterGroupCreated: $setup.filterGroupCreated,
      onClose: $setup.closeSaveFiltersModal
    }, null, 8, ["showModal", "groupedFilter", "filterByOrderType", "category", "showTargetShippingDate"])
  ], 64))
}