import { ref, Ref } from "vue";

export default (tableRef: Ref<HTMLElement | undefined>) => {
  let resizeObserver;

  const trackOrdersTitleClass = "track-orders-title";
  const trackOrdersParamsClass = "track-orders-params";
  const trackOrdersPaginationClass = "track-orders--page-counters";
  const tableHeaderClass = "n-data-table-base-table-header";
  const tableBodyClass = "n-data-table-tbody";

  const trackOrdersTitleHeight = ref(0);
  const trackOrdersParamsHeight = ref(0);
  const trackOrdersPaginationHeight = ref(0);
  const tableHeaderHeight = ref(0);
  const tableBodyHeight = ref(0);

  const trackOrdersTitleMargin = 33;
  const trackOrdersParamsMargin = 17;
  const trackOrdersPaginationMargin = 20;
  const bodyTableMargin = 2;

  const getHTMLElementByClassName = (className: string) =>
    tableRef.value?.getElementsByClassName(className)[0] as HTMLElement;

  const checkElementByClassName = (
    entry: ResizeObserverEntry,
    className: string
  ) => entry.contentBoxSize && entry.target.classList.value.includes(className);

  const setResizeObserver = () => {
    const trackOrdersTitle = getHTMLElementByClassName(trackOrdersTitleClass);
    const trackOrdersParams = getHTMLElementByClassName(trackOrdersParamsClass);
    const trackOrdersPagination = getHTMLElementByClassName(
      trackOrdersPaginationClass
    );
    const tableHeader = getHTMLElementByClassName(tableHeaderClass);
    const tableBody = getHTMLElementByClassName(tableBodyClass);

    resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (checkElementByClassName(entry, trackOrdersTitleClass)) {
          trackOrdersTitleHeight.value =
            entry.contentBoxSize[0].blockSize + trackOrdersTitleMargin;
        }
        if (checkElementByClassName(entry, trackOrdersParamsClass)) {
          trackOrdersParamsHeight.value =
            entry.contentBoxSize[0].blockSize + trackOrdersParamsMargin;
        }
        if (checkElementByClassName(entry, trackOrdersPaginationClass)) {
          trackOrdersPaginationHeight.value =
            entry.contentBoxSize[0].blockSize + trackOrdersPaginationMargin;
        }
        if (checkElementByClassName(entry, tableHeaderClass)) {
          tableHeaderHeight.value = entry.contentBoxSize[0].blockSize;
        }
        if (checkElementByClassName(entry, tableBodyClass)) {
          tableBodyHeight.value =
            entry.contentBoxSize[0].blockSize + bodyTableMargin;
        }
      }
    });

    if (trackOrdersTitle) {
      resizeObserver.observe(trackOrdersTitle);
    }
    if (trackOrdersParams) {
      resizeObserver.observe(trackOrdersParams);
    }
    if (trackOrdersPagination) {
      resizeObserver.observe(trackOrdersPagination);
    }
    if (tableHeader) {
      resizeObserver.observe(tableHeader);
    }
    if (tableBody) {
      resizeObserver.observe(tableBody);
    }
  };

  return {
    setResizeObserver,
    trackOrdersTitleHeight,
    trackOrdersParamsHeight,
    trackOrdersPaginationHeight,
    tableHeaderHeight,
    tableBodyHeight,
  };
};
