import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/placeholders/empty-state.svg'


const _withScopeId = n => (_pushScopeId("data-v-2fddf180"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "empty-state--wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "empty-state--icon",
  src: _imports_0
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.showDefaultIcon)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}