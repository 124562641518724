import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "view-as-a-customer-modal main-modal",
    preset: "dialog",
    "show-icon": false,
    title: "View as a customer"
  }, {
    default: _withCtx(() => [
      _createVNode($setup["SelectCustomer"], { onSelect: $setup.onSelectCustomer })
    ]),
    _: 1
  }))
}