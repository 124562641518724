import { computed, ref } from "vue";
import quoteAdminStatuses from "@/constants/quoteAdminStatuses";
import complexityLevels from "@/constants/complexityLevels";
import { QuoteStateFilterKey } from "@/enums/QuoteStateFilterKey";
import {
  FilterComplexityInterface,
  FilterCustomerInterface,
  FilterAssignedToInterface,
  CountersDataAdminInterface,
  FilterStatusInterface,
  FilterStateInterface,
} from "@/types/api/QuoteFiltersInterface";
import quoteStateFilter from "@/constants/filters/quoteStateFilter";
import customerStateFilter from "@/constants/filters/customerStateFilter";

export default () => {
  const showFilters = ref(false);

  const filterMenuScreens = {
    Filters: "filters",
    Status: "status",
    AssignedTo: "assignedTo",
    Customer: "customer",
    Complexity: "complexity",
    State: "state",
  };

  const statuses = ref<FilterStatusInterface[]>([]);
  const assignedToList = ref<(FilterAssignedToInterface | undefined)[]>([]);
  const customers = ref<(FilterCustomerInterface | undefined)[]>([]);
  const complexities = ref<FilterComplexityInterface[]>([]);
  const quoteState = ref<FilterStateInterface[]>([]);
  const customerList = ref<
    Pick<FilterCustomerInterface, "id" | "fullName" | "name" | "number">[]
  >([]);
  const userList = ref<
    Pick<
      FilterAssignedToInterface,
      "avatar" | "firstName" | "id" | "lastName"
    >[]
  >([]);

  const fillFiltersOptionData = (
    counters: CountersDataAdminInterface
  ): void => {
    statuses.value = quoteAdminStatuses.map((item) => {
      return {
        count: counters.status[item.key] || 0,
        status: item.value,
      };
    });
    customers.value = Object.keys(counters.customerId)
      .map((item) => {
        const customer = customerList.value.find(
          (customerItem) => customerItem.id === item
        );
        return customer
          ? {
              count: counters.customerId[item],
              customerId: item,
              ...customer,
            }
          : undefined;
      })
      .filter((item) => !!item);
    assignedToList.value = Object.keys(counters.assignedToId)
      .filter((assignedToId) => assignedToId)
      .map((item) => {
        const assignedUser = userList.value.find(
          (userItem) => userItem.id === item
        );
        return assignedUser
          ? {
              count: counters.assignedToId[item],
              ...assignedUser,
              fullName: `${assignedUser.firstName} ${assignedUser.lastName}`,
            }
          : undefined;
      });

    complexities.value = complexityLevels.map((item) => {
      return {
        count: counters.complexity[item.key] || 0,
        level: item.value,
      };
    });

    quoteState.value = (
      Object.keys(customerStateFilter) as Array<
        keyof typeof customerStateFilter
      >
    ).map((item) => {
      return {
        count: counters.isDeleted[item] || 0,
        label: customerStateFilter[item].label,
        value: JSON.parse(item)
          ? QuoteStateFilterKey.deleted
          : QuoteStateFilterKey.active,
      };
    });
  };

  const filtersScreen = ref(filterMenuScreens.Filters);

  const filterByStatus = ref(new Set<string>());
  const filterByAssignedTo = ref(new Map<string, string>());
  const filterByCustomer = ref(new Map<string, string>());
  const filterByComplexity = ref(new Set<string>());
  const filterByQuoteState = ref(
    new Set<QuoteStateFilterKey>([QuoteStateFilterKey.active])
  );
  const statusesIndexArray = computed(() => {
    return getIndexArrayFromFilterSet(filterByStatus.value, quoteAdminStatuses);
  });
  const complexityIndexArray = computed(() => {
    return getIndexArrayFromFilterSet(
      filterByComplexity.value,
      complexityLevels
    );
  });

  const getIndexArrayFromFilterSet = (
    filterSet: Set<string>,
    valueArray: { key: number; value: string }[]
  ): (number | undefined)[] => {
    return [...filterSet].map((item) => {
      return valueArray.find((valueArrayItem) => valueArrayItem.value === item)
        ?.key;
    });
  };

  const searchSet = ref<Set<string>>(new Set());

  const customerIdArray = ref<string[]>([]);
  const assignedToIdArray = ref<string[]>([]);
  const mainFilterObject = computed(() => {
    {
      const [filterByQuoteStateItem] = filterByQuoteState.value;
      return {
        ...(statusesIndexArray.value.length && {
          status: statusesIndexArray.value,
        }),
        ...(filterByAssignedTo.value.size && {
          assignedToId: [...filterByAssignedTo.value.keys()],
        }),
        ...(customerIdArray.value.length && {
          customerId: [...customerIdArray.value],
        }),
        ...(assignedToIdArray.value.length && {
          assignedToId: [...assignedToIdArray.value],
        }),
        ...(complexityIndexArray.value.length && {
          complexity: complexityIndexArray.value,
        }),
        ...(searchSet.value.size > 0 && {
          search: [...searchSet.value.keys()],
        }),
        ...(filterByQuoteStateItem && {
          isDeleted: quoteStateFilter[filterByQuoteStateItem].value,
        }),
      };
    }
  });

  const formatObjectToQuery = (
    filterObject: Record<string, string | number | (string | number)[]>
  ) => {
    return Object.keys(filterObject)
      .map((key) => {
        const objectValue = filterObject[key];
        if (objectValue instanceof Array) {
          return (objectValue as string[])
            .map((value: string) => `${key}[]=${value}`)
            .join("&");
        } else {
          return `${key}=${encodeURIComponent(objectValue)}`;
        }
      })
      .join("&");
  };

  return {
    showFilters,
    filterMenuScreens,
    statuses,
    assignedToList,
    customers,
    complexities,
    quoteState,
    fillFiltersOptionData,
    filtersScreen,
    filterByStatus,
    filterByAssignedTo,
    filterByCustomer,
    filterByComplexity,
    filterByQuoteState,
    statusesIndexArray,
    complexityIndexArray,
    searchSet,
    getIndexArrayFromFilterSet,
    mainFilterObject,
    formatObjectToQuery,
    customerIdArray,
    assignedToIdArray,
    customerList,
    userList,
  };
};
