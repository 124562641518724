import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2565ba99"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-action-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.showEscalateModal)
      ? (_openBlock(), _createBlock($setup["EscalateModal"], {
          key: 0,
          show: $setup.showEscalateModal,
          "onUpdate:show": [
            _cache[0] || (_cache[0] = ($event: any) => (($setup.showEscalateModal) = $event)),
            $setup.onEscalateModalClose
          ],
          id: $props.id,
          orderType: $props.orderType,
          flaggedLevel: $props.flaggedLevel,
          onClose: $setup.onEscalateModalClose,
          onEscalationUpdated: $setup.onEscalationUpdated
        }, null, 8, ["show", "id", "orderType", "flaggedLevel"]))
      : _createCommentVNode("", true),
    _createVNode($setup["DotsMenu"], {
      options: $setup.menuOptions,
      onSelectOption: $setup.handleMenuOptionSelect,
      borderless: "",
      transparent: "",
      size: 10
    }, null, 8, ["options"])
  ]))
}