import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f64eda7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "track-orders-summary"
}
const _hoisted_2 = {
  key: 0,
  class: "track-orders-summary-close-mode"
}
const _hoisted_3 = { class: "summary--wrapper" }
const _hoisted_4 = { class: "summary--title" }
const _hoisted_5 = {
  key: 1,
  class: "track-orders-summary-open-mode"
}
const _hoisted_6 = {
  class: "left-side",
  id: "track-orders-summary-left-side"
}
const _hoisted_7 = { class: "summary--table" }
const _hoisted_8 = { class: "summary--table-headline" }
const _hoisted_9 = { class: "title" }
const _hoisted_10 = { class: "right-side" }
const _hoisted_11 = { class: "chart-filters" }
const _hoisted_12 = { class: "filter-by" }
const _hoisted_13 = { class: "group-by" }
const _hoisted_14 = {
  key: 0,
  class: "group-by-time"
}
const _hoisted_15 = { class: "group-by-type" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.data.totals?.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($setup.isCloseMode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.closeModeOptions, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "summary--item",
                    key: item.title
                  }, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(item.title), 1),
                    _createElementVNode("div", {
                      class: _normalizeClass(["summary--value", item?.class])
                    }, _toDisplayString(item.value), 3)
                  ]))
                }), 128)),
                _createVNode($setup["NButton"], {
                  class: "circle-button close-mode-button",
                  circle: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.isCloseMode = false))
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["NIcon"], {
                      component: $setup.ArrowDown,
                      size: "8",
                      color: "#c0c0c0"
                    }, null, 8, ["component"])
                  ]),
                  _: 1
                })
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.openModeTableHeadlines, (item) => {
                      return _createElementVNode("div", {
                        class: "summary--table-headline-item",
                        key: item
                      }, _toDisplayString(item), 1)
                    }), 64))
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.openModeOptions, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "summary--table-rows",
                      key: item.title
                    }, [
                      _createElementVNode("div", _hoisted_9, _toDisplayString(item.title), 1),
                      _createElementVNode("div", {
                        class: _normalizeClass(["lines", item?.class])
                      }, _toDisplayString(item.lines), 3),
                      _createElementVNode("div", {
                        class: _normalizeClass(["amount", item?.class])
                      }, _toDisplayString(item.amount), 3)
                    ]))
                  }), 128))
                ]),
                _createVNode($setup["NButton"], {
                  class: "circle-button open-mode-button",
                  circle: "",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.isCloseMode = true))
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["NIcon"], {
                      component: $setup.ArrowDown,
                      size: "8",
                      color: "#c0c0c0"
                    }, null, 8, ["component"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.filterByButtons, (item) => {
                      return _createVNode($setup["NButton"], {
                        class: _normalizeClass(["n-button-filter chart-filter-button", {active: $props.activeGroupByKey === item.key}]),
                        key: item.key,
                        onClick: ($event: any) => ($setup.onClickFilterByButton(item.key)),
                        round: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.title), 1)
                        ]),
                        _: 2
                      }, 1032, ["class", "onClick"])
                    }), 64))
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    ($props.activeGroupByKey === $setup.GroupByEnum.TARGET_SHIPPING_DATE)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                          (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.groupByTimeButtons, (item) => {
                            return _createVNode($setup["NButton"], {
                              class: _normalizeClass(["text-button group-by-button", {active: $props.activeGroupByTimeKey === item.key}]),
                              key: item.key,
                              onClick: ($event: any) => ($setup.onClickGroupByTimeButton(item.key))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.title), 1)
                              ]),
                              _: 2
                            }, 1032, ["class", "onClick"])
                          }), 64))
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_15, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.groupByTypeButtons, (item) => {
                        return _createVNode($setup["NButton"], {
                          class: _normalizeClass(["text-button group-by-button", {active: $setup.activeGroupByTypeKey === item.key}]),
                          key: item.key,
                          onClick: ($event: any) => ($setup.onClickGroupByTypeButton(item.key))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.title), 1)
                          ]),
                          _: 2
                        }, 1032, ["class", "onClick"])
                      }), 64))
                    ])
                  ])
                ]),
                _createElementVNode("div", {
                  class: "bar-chart-container",
                  style: _normalizeStyle([$setup.sidebarWidthOffset, $setup.trackOrdersSummaryTotalOffset])
                }, [
                  _createVNode($setup["BarChart"], {
                    class: "bar-container",
                    data: $props.data.sections,
                    activeGroupByTimeKey: $props.activeGroupByTimeKey,
                    activeGroupByTypeKey: $setup.activeGroupByTypeKey,
                    activeGroupByKey: $props.activeGroupByKey
                  }, null, 8, ["data", "activeGroupByTimeKey", "activeGroupByTypeKey", "activeGroupByKey"])
                ], 4)
              ])
            ]))
      ]))
    : _createCommentVNode("", true)
}