import OrderInterface from "@/types/OrderInterface";
import { h } from "vue";
import { NSpin } from "naive-ui";
import TextWithCopyButton from "@/components/ui/TextWithCopyButton.vue";
import TextWithTooltip from "@/components/ui/TextWithTooltip.vue";
import flaggedLevelMethods from "@/composables/flaggedLevelMethods";

export default () => {
  const { getFlaggedLevelClass } = flaggedLevelMethods();
  const textWithTooltipAndCopyButton = (
    copiedValue: string,
    hidden: boolean,
    text: string,
    additionalClass = []
  ) => {
    return h(
      TextWithCopyButton,
      {
        copiedValue: copiedValue || "",
        hidden,
        class: [...additionalClass],
      },
      () =>
        h(
          "span",
          {
            class: ["number", ...additionalClass],
          },
          h(TextWithTooltip, {
            text,
          })
        )
    );
  };

  const orderThumbnail = (previewLoading: boolean, thumbnailUrl?: string) => {
    return previewLoading
      ? h("div", { class: "img-loader" }, h(NSpin, { size: 24 }))
      : thumbnailUrl
      ? h("img", { src: thumbnailUrl })
      : h("img", {
          src: require("@/assets/placeholders/order-details/order-thumbnail-placeholder.png"),
        });
  };

  const partInfoCell = (previewLoading: boolean, additionalClass: []) => {
    return {
      title: "Part info",
      key: "partInfo",
      width: 375,
      render(row: OrderInterface) {
        return h(
          "div",
          {
            class: "part-info-container",
          },
          [
            h("div", { class: [...additionalClass] }, [
              orderThumbnail(previewLoading, row.thumbnailUrl),
              h("div", { class: ["part-info-pn-rev"] }, [
                textWithTooltipAndCopyButton(
                  `${row.partNumber}${row.partRev ? `-${row.partRev}` : ""}`,
                  false,
                  `${row.partNumber}${row.partRev ? `-${row.partRev}` : ""}`
                ),
                h(
                  "span",
                  {
                    class: ["number", "description"],
                  },
                  h(TextWithTooltip, {
                    text: `${row?.partId?.description || ""}`,
                  })
                ),
              ]),
            ]),
            h("div", { class: "part-info-statuses" }, [
              row.newNotifications
                ? h("div", { class: "new-notifications" })
                : undefined,
              row.isFA ? h("div", { class: "first-article" }) : undefined,
              row.flaggedLevel
                ? h("div", {
                    class: [
                      "flagged-level",
                      getFlaggedLevelClass(row.flaggedLevel),
                    ],
                  })
                : undefined,
              row.newMessagesCount
                ? h("div", { class: "new-messages-count" }, [
                    h("div"),
                    h("span", row.newMessagesCount),
                  ])
                : undefined,
            ]),
          ]
        );
      },
    };
  };
  return {
    partInfoCell,
    textWithTooltipAndCopyButton,
    orderThumbnail,
  };
};
